<template>
  <v-row no-gutters align="center" style="height: 100vh">
    <v-col cols="12">
      <v-row class="mt-16" align="center">
        <v-col cols="7" class="justify-center px-8">
          <div class="px-8">
            <v-img
              class="mx-auto"
              :src= "localizedImage"
              width="40vw"
              contain
              :eager="true"
            />
            <div v-if="logo != null" class="mt-16">
              <div class="black--text text-h7 text-center font-weight-bold">
                Partnered with
              </div>
              <v-img
                class="mx-auto mt-4"
                :src="logo"
                max-height="120px"
                contain
              />
            </div>
          </div>
        </v-col>
        <v-col cols="5" class="justify-center px-16">
          <progress-indicator :steps="4" :currentStep="2"></progress-indicator>
          <div v-if="canUpgradeSubscription && !paidToFreeUpgradeBlock">
            <div
              class="mt-16 primary--text font-weight-bold text-center text-h5"
            >
              {{ pageTitle }}
            </div>
            <div
              class="
                mt-2
                secondaryGrey--text
                font-weight-bold
                text-center text-body1
              "
            >
              {{ pageSubtitle }}
            </div>
            <div class="mx-auto" style="max-width: 500px">
              <v-row align-content="space-around" class="my-8">
                <v-col :cols="isSmall ? '12' : '6'">
                  <plan-card
                    :class="isSmall ? 'mx-auto' : 'ml-auto'"
                    title="Current Plan"
                    subtitle="Full access to Withu"
                    :body="currentCost"
                  ></plan-card>
                </v-col>
                <v-col :cols="isSmall ? '12' : '6'">
                  <plan-card
                    :class="isSmall ? 'mx-auto' : 'mr-auto'"
                    title="New offer"
                    subtitle="Full access to Withu"
                    :body="newDeal"
                    :highlight="true"
                  ></plan-card>
                </v-col>
                <v-btn
                  color="primary"
                  light
                  class="
                    mx-auto
                    white--text
                    font-weight-bold
                    text-subtitle-1
                    mt-8
                    px-10
                  "
                  :max-width="300"
                  @click="tryUpgradeSubscription"
                  :loading="isLoading"
                  >Update subscription</v-btn
                >
              </v-row>
            </div>
          </div>
          <div v-else-if="paidToFreeUpgradeBlock">
            <div
              class="mt-16 primary--text font-weight-bold text-center text-h5"
            >
              Oops! Sorry we can't upgrade your subscription
            </div>
            <div
              class="
                mt-2
                secondaryGrey--text
                font-weight-bold
                text-center text-body1
              "
            >
              Please contact member services below for assistance.
            </div>
          </div>
          <div v-else>
            <div
              class="primary--text text-center font-weight-bold mt-16"
              style="white-space: pre-wrap"
            >
              {{ cancelTitle }}
            </div>
            <v-img
              class="mx-auto my-16"
              src="@/assets/cancel.png"
              lazy-src="@/assets/cancel-lo.png"
              max-width="364"
              contain
              :eager="true"
            />
            <div class="d-flex justify-center">
              <v-btn
                color="primary"
                light
                class="white--text font-weight-bold text-subtitle-1 mt-8 px-10"
                :loading="isLoading"
                :width="300"
                :max-width="300"
                @click="checkSubscription"
                >Continue to payment page</v-btn
              >
            </div>
            <div
              v-if="failedSubscriptionCheck"
              class="
                red--text
                text-caption text-center
                font-weight-bold
                mt-4
                mx-8
              "
            >
              *Your existing subscription is still active. Please follow the
              steps above before continuing to the payment page.
            </div>
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import upgrade from "../upgrade";
export default {
  mixins: [upgrade],
  computed: {
    isSmall() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    localizedImage() {
      switch (this.selectedLocale.key) {
        case "es":
          return require("@/assets/desktop-redeem-es.png");
        case "pt":
          return require("@/assets/desktop-redeem-pt.png");
        case "en":
          return require("@/assets/desktop-redeem.png");
      }
    },
  },
};
</script>