<template>
  <v-card
    class="pa-8"
    elevation="2"
    width="100%"
    :max-width="width"
    :min-height="height"
    :class="highlight ? 'card-highlighted' : ''"
  >
    <div class="text--primary font-weight-bold text-h6 text-center">
      {{ title }}
    </div>
    <div class="text--secondaryGrey text-caption text-center">
      {{ subtitle }}
    </div>
    <div
      class="text--secondaryGrey font-weight-bold body-1 text-center mt-8"
      style="white-space: pre-line"
    >
      {{ body }}
    </div>
  </v-card>
</template>

<style scoped>
.card-highlighted {
  border-style: solid;
  border-width: 1px;
  border-color: rgba(112, 104, 130, 0.4);
}
</style>

<script>
export default {
  props: {
    title: String,
    subtitle: String,
    body: String,
    highlight: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "250px",
    },
    height: {
      type: String,
      default: "250px",
    },
  },
};
</script>