<template>
  <div>
    <div v-if="isMobile">
      <mobile-view></mobile-view>
    </div>
    <div v-else>
      <desktop-view></desktop-view>
    </div>
  </div>
</template>

<script>
import desktopView from "./components/desktop-view.vue";
import mobileView from "./components/mobile-view.vue";
import upgrade from "./upgrade";
export default {
  components: {
    desktopView,
    mobileView,
  },
  mixins: [upgrade],
};
</script>