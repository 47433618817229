<template>
  <div class="mt-16">
    <div v-if="!showCancel && !paidToFreeUpgradeBlock">
      <v-row align="center">
        <v-col cols="12">
          <div class="mt-16 primary--text font-weight-bold text-center text-h5">
            {{ pageTitle }}
          </div>
          <div
            class="
              mt-2
              secondaryGrey--text
              font-weight-bold
              text-center text-body1
            "
          >
            {{ pageSubtitle }}
          </div>
          <plan-card
            class="mx-auto mt-8"
            :title="currentPlanConfig.title"
            :subtitle="currentPlanConfig.subtitle"
            :body="currentPlanConfig.show_price ? currentCost : ''"
            height="135px"
            :width="cardWidth"
          ></plan-card>
          <plan-card
            class="mx-auto mt-4"
            :title="newPlanConfig.title"
            :subtitle="newPlanConfig.subtitle"
            :body="newDeal"
            :highlight="true"
            height="135px"
            :width="cardWidth"
          ></plan-card>
        </v-col>
        <v-btn
          color="primary"
          light
          class="
            mx-auto
            white--text
            font-weight-bold
            text-subtitle-1
            mt-8
            px-10
          "
          :max-width="cardWidth"
          @click="tryUpgradeSubscription"
          :loading="isLoading"
          >Update subscription</v-btn
        >
      </v-row>
    </div>
    <div v-else-if="paidToFreeUpgradeBlock">
      <v-row align="center">
        <v-col class="justify-center px-16" cols="12">
          <div class="mt-16 primary--text font-weight-bold text-center text-h5">
            Oops! Sorry we can't upgrade your subscription
          </div>
          <div
            class="
              mt-2
              secondaryGrey--text
              font-weight-bold
              text-center text-body1
            "
          >
            Please contact member services below for assistance.
          </div>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <v-row align="center">
        <v-col cols="12">
          <div
            class="
              mx-8
              mt-16
              primary--text
              font-weight-bold
              text-center text-h6
            "
            style="white-space: pre-wrap"
          >
            {{ cancelTitle }}
          </div>
          <div style="margin-left: -40px">
            <v-img
              class="mx-auto my-16"
              src="@/assets/cancel-mobile.png"
              lazy-src="@/assets/cancel-mobile-lo.png"
              max-width="170px"
              contain
              :eager="true"
            ></v-img>
          </div>
        </v-col>

        <v-btn
          color="primary"
          light
          class="
            white--text
            font-weight-bold
            text-subtitle-1
            mx-auto
            mt-2
            px-10
          "
          :loading="isLoading"
          :width="300"
          :max-width="300"
          @click="checkSubscription"
          >Continue to payment page</v-btn
        >

        <div
          v-if="failedSubscriptionCheck"
          class="red--text text-caption text-center font-weight-bold mt-4 mx-8"
        >
          *Your existing subscription is still active. Please follow the steps
          above before continuing to the payment page.
        </div>
      </v-row>
    </div>
    <!-- Bit extra height for clearing the help icon -->
    <div style="width: 100%; height: 150px"></div>
  </div>
</template>

<script>
import upgrade from "../upgrade";
export default {
  mixins: [upgrade],
  computed: {
    cardWidth() {
      return `${window.innerWidth * 0.8}px`;
    },
  },
};
</script>