import { mapState, mapActions, mapGetters } from "vuex";
import progressIndicator from "@/components/progress-indicator";
import planCard from "./components/plan-card";
import { router } from "@/router";
import { getStringForKey, getDealStringForKey } from "@/translations/utilities";

export default {
  components: {
    progressIndicator,
    planCard,
  },
  data() {
    return {
      isLoading: false,
      showCancel: false,
      failedSubscriptionCheck: false,
    };
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
    ...mapGetters({
      deal: "account/deal",
      upgrade: "account/upgrade",
      freeTrial: "account/freeTrial",
      products: "account/products",
      currency: "account/currency",
      canUpgradeSubscription: "account/canUpgradeSubscription",
      userFirstName: "account/firstName",
      paidToFreeUpgradeBlock: "account/paidToFreeUpgradeBlock",
      selectedLocale: "account/getSelectedLocale",
      dealStrings: "account/getDealStrings",
    }),
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
    logo() {
      return getDealStringForKey(
        this.dealStrings,
        this.selectedLocale,
        "content.logo"
      );
    },
    product() {
      return this.upgrade.currentProduct.term == "month"
        ? this.products[1]
        : this.products[0];
    },
    price() {
      return this.product.prices.find((p) => p.currency == this.currency);
    },
    currentCost() {
      let currentProduct = this.upgrade.currentProduct;
      return `${currentProduct.symbol}${currentProduct.price}/${currentProduct.term}`;
    },
    newDeal() {
      var str = "";
      if (this.freeTrial) {
        str = `${this.freeTrial} ${getStringForKey(
          this.selectedLocale,
          "free"
        )}`;
      }

      if (str) {
        str += `,\n${getStringForKey(
          this.selectedLocale,
          "then"
        ).toLowerCase()}`;
      }

      let price = this.price.price;
      var subscription = this.deal.subscription;
      if (subscription && subscription.discount_percent) {
        var discount = price * (subscription.discount_percent / 100);
        price = price - discount;
      }

      str += `${this.price.symbol}${price.toFixed(2)}/${this.product.term}`;

      return str;
    },
    firstName() {
      var userName = this.userFirstName;
      if (userName == null) {
        return "We ";
      }
      return userName;
    },
    pageTitle() {
      return getStringForKey(this.selectedLocale, "alreadyHaveSub");
    },
    pageSubtitle() {
      return getStringForKey(this.selectedLocale, "compareOffer");
    },
    currentPlanConfig() {
      return {
        title: getStringForKey(this.selectedLocale, "currentPlan"),
        subtitle: getStringForKey(this.selectedLocale, "fullAccess"),
        show_price: true,
      };
    },
    newPlanConfig() {
      return {
        title: getStringForKey(this.selectedLocale, "newOffer"),
        subtitle: getStringForKey(this.selectedLocale, "fullAccess"),
      };
    },
    cancelTitle() {
      return `${this.firstName}, ${getStringForKey(
        this.selectedLocale,
        "fewMoreThings"
      )}`;
    },
  },
  methods: {
    ...mapActions({
      updateSubscriptionStatus: "account/updateSubscriptionStatus",
      upgradeSubscription: "account/upgrade",
    }),
    tryUpgradeSubscription() {
      if (this.upgrade.canUpgrade) {
        var payload = {
          priceId: this.price.price_id,
          productId: this.product.product_id,
        };

        // //TODO: get actual new priceId and productId
        // //use referringLink https://download.withu.fit/e/FOv5YUbSvkb
        // //to simulate a deal with 50% off coupon
        // var payload = {
        //     priceId: 'price_1IgpxELdUrrOSabiI5CaHsZp',
        //     productId: 'prod_IzmxqbqpMRV1YH'
        // }
        debugger;
        let self = this;
        self.isLoading = true;

        self
          .upgradeSubscription(payload)
          .then(() => {
            self.isLoading = false;
            router.push("/download");
          })
          .catch((e) => {
            self.isLoading = false;
          });
      } else {
        //Show cancellation page
        this.showCancel = true;
      }
    },
    checkSubscription() {
      //Check they cancelled their sub
      let self = this;
      self.isLoading = true;

      self
        .updateSubscriptionStatus()
        .then(() => {
          self.isLoading = false;
          self.failedSubscriptionCheck = false;
          if (self.canUpgrade) {
            router.push("/subscribe");
          } else {
            self.failedSubscriptionCheck = true;
          }
        })
        .catch((e) => {
          self.isLoading = false;
        });
    },
  },
  mounted() {},
};
